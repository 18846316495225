/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, title, topFlag, children }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteName
            siteUrl
            description
            author
            ogpImage
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const imgPath = site.siteMetadata?.ogpImage;
  let ogTitle = title + ' | ' + site.siteMetadata?.title;
  let pageTitle =  title ? title + ' | ' + site.siteMetadata?.title : site.siteMetadata?.title;
    if (topFlag){
        ogTitle = site.siteMetadata?.title;
        pageTitle = site.siteMetadata?.title;
    }
  return (
    <>
      <title>{pageTitle}</title>
      <meta property="og:site_name" content={site.siteName}></meta>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:url" content={site.siteMetadata?.siteUrl} />
      <meta property="og:image" content={imgPath} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={site.siteMetadata?.author || ``} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      {children}
    </>
  );
}

Seo.defaultProps = {
    description: ``,
    title: ``,
    topFlag: false,
}

Seo.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string.isRequired,
    topFlag: PropTypes.bool,
}

export default Seo
