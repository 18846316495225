import React from "react"
import Layout from "components/layout"
import Seo from "components/seo"
import PageLead from "../../../components/pageLead";
import * as FadeIn from "components/fadein";

const MessagePage = () => {
    return (
        <Layout>
            <div className="message-page">
                <PageLead sub="COMPANY"
                          title="メッセージ"
                />
                <FadeIn.Up>
                    <section className="image-wrap">
                        <img src="https://stgokulabweb.blob.core.windows.net/images/message/fv_ceo.jpg"
                             alt="CEO"/>
                    </section>
                </FadeIn.Up>
                <FadeIn.Up>
                    <section className="contents-wrap">
                        <h2>洗濯からライフスタイルを豊かに。<br />
                            コインランドリーだから出来ることを<br />
                            伝え続けたい。</h2>
                        <div className="ceo-name">久保田 淳　代表取締役CEO</div>
                        <div className="body">
                            <p>コインランドリーに初めて足を運び、プロユースの機器とこだわりの洗剤で仕上げた洗濯物の仕上がりを体験すると、誰もが感動をおぼえます。ふわふわと柔らかなタオルや毛布、真っ白で爽やかな風合いのシャツ。その生まれ変わったような肌触りに心満たされます。</p>
                            <br/>
                            <p>「洗濯」は、人の暮らしに欠かせないものですが、実は課題があります。大きなものなど家庭用洗濯機では洗えないものもあり、さらに家庭での洗濯には時間と労力が必要です。そんな課題に対して、ランドリーが解決策となるはずです。感動の仕上がりを求めたい、天候に左右されず短時間で一気に洗濯物をスッキリ片付けたい。そんな人々の気持ちに応え、暮らしに貢献できるのがランドリーです。</p>
                            <br/>
                            <p>「ランドリーのある新しいライフスタイルを提案し、豊かな社会をつくりたい」そんな想いで私たちOKULABがスタートしました。</p>
                            <br/>
                            <p>2016年の創業当初よりセルフランドリー事業を通して、一人ひとりのお客様の生活に寄り添う居心地のいい空間づくりや、何度も通いたくなるようなサービスを追求してきました。街それぞれに暮らし方や求められるものが異なるなか、すべての店舗やサービスは利用するお客様の目線でつくられ、地域住民の皆さんに愛される存在であるべきだと考えています。</p>
                            <br/>
                            <p>コインランドリー市場は急成長を遂げ、拡大し続けている今、お客様からは利用し続けたいと思えるような本質的なサービスが求められています。OKULABは、新たなニーズに耳を傾け、常に必要とされ、満足していただくための挑戦をしています。</p>
                            <br/>
                            <p>「いかに安心かつ便利なサービスをお客様にご利用いただくか」<br className="pc"/>
                            「いかにお客様と継続的な絆を築いていくか」</p>
                            <br/>
                            <p>を信念に、OKULABとともにつくる明日が今日より少しでも豊かなものになるように。お客様から信頼いただけるサービスを提案し、ランドリー市場をより価値の高い産業へ成長させるという使命感をもって進み続けていきます。</p>
                            <br/>
                            <br/>
                            <p>
                                株式会社OKULAB<br/>
                                代表取締役CEO　久保田 淳
                            </p>
                        </div>
                    </section>
                </FadeIn.Up>
            </div>
        </Layout>
    )
}

export const Head = () => (
    <Seo
        title="代表メッセージ"
        description="OKULAB（オクラボ）の代表取締役CEO 久保田淳のメッセージ。洗濯からライフスタイルを豊かに。コインランドリーだから出来ることを伝え続けたい。"
    />
)
export default MessagePage


